import { admin2 } from '@/api/instance'

export const FindTicketConfig = async ({
  shopId,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/classTicketConfig`,
  })
}

export const UpdateTicketConfig = async ({
  shopId,
  clientUsageMustSelectBranch,
  clientUsageTimesLimit,
  clientUsageTimesMax,
  clientGiveAllowed,
  expireNotificationBefore,
  displayName,
  displayUnit,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/classTicketConfig`,
    data: {
      clientUsageMustSelectBranch,
      clientUsageTimesLimit,
      clientUsageTimesMax,
      clientGiveAllowed,
      expireNotificationBefore,
      displayName,
      displayUnit,
    },
  })
}
